<template>
  <section class="desgin">

    <Loader :loading="showLoader" />
    <div class="row flex-grow">
      <div class="row flex-grow">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Edit Contact Us</h4>
              <form  @submit.prevent="editContactDetails" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
  
                    <div role="group" class="form-group">
                      <label class="d-block">Address</label>
                      <div>
                        <textarea class="form-control" v-model="contactDetails.address" rows="6"></textarea>
                      </div>
                    </div>
                    <div role="group" class="form-group">
                      <label class="d-block">whatsapp No</label>
                      <div>
                        <input type="text" laceholder="whatsapp No"  v-model="contactDetails.whatsapp" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Mobile No</label>
                      <div>
                        <input type="text" placeholder="Mobile No" v-model="contactDetails.phone" class="form-control">
                      </div>
                    </div>
                    <div role="group" class="form-group">
                      <label class="d-block">Email</label>
                      <div>
                        <input type="text" placeholder="Email" v-model="contactDetails.email" class="form-control">
                      </div>
                    </div>
                    <div role="group" class="form-group">
                      <label class="d-block">YouTube URL</label>
                      <div>
                        <input type="text" laceholder="URL"  v-model="contactDetails.socalY" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Linkedin URL </label>
                      <div>
                        <input type="text" placeholder="URL" v-model="contactDetails.socalIN" class="form-control">
                      </div>
                    </div>
                    <div role="group" class="form-group">
                      <label class="d-block"> Instagram URL </label>
                      <div>
                        <input type="text" placeholder="URL" v-model="contactDetails.socalI" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Twitter URL</label>
                      <div>
                        <input type="text" placeholder="URL" v-model="contactDetails.socalX"  class="form-control">
                      </div>
                    </div>
                    <div role="group" class="form-group">
                      <label class="d-block">Facebook URL </label>
                      <div>
                        <input type="text" placeholder="URL" v-model="contactDetails.socalFB" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  
                </div>
                <button type="submit" class="btn mr-2 btn-primary">Update</button>
              </form>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import { sliderApi } from "../api";
export default {
  name: 'contactDetails',
  components: {
    Loader,
  },
  data() {
    return {
      showLoader: false, 
      contactDetails: "", 
    };
  },
  mounted() {
    this.ContactDetailsById();
  },
  methods: {

    async ContactDetailsById() {
      this.showLoader = true;
      const { data } = await sliderApi.getContactDetailsById(`1`);
      this.contactDetails = data.data.data;
      this.showLoader = false;
    },

      async editContactDetails() {
      this.showLoader = true;
      const data = await sliderApi.updateContactDetails(this.contactDetails._id, {
        ...this.contactDetails,
      });
      this.showLoader = true;
      this.ContactDetailsById();
      if (data.status == 200) {
        
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
      this.$router.push("/contact");
    },

  }
}
</script>
